import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Edit, Visibility, Delete } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
// import { sentMailToUser } from 'redux/actions/Users';
// import { getIssueManagement, updateIssueManagementStatus } from 'redux/actions/IssueManagement';
import { Activate, Deactivate } from '@jumbo/constants/TableLableConstant';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { fetchVirsionData, pushVirsionData, deleteVersion } from '../../../../redux/actions/Setting';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    fontWeight: '300',
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    { action: 'edit', label: 'Edit', icon: <Edit /> },
  ];

  if (user.status === 1) {
    actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }
  actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getIssueManagementParams }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getIssueManagementParams;
  const { VirsionList } = useSelector(({ SETTING }) => SETTING);
  const classes = useStyles();
  const dispatch = useDispatch();

  const versionsData = VirsionList?.versions;
  const allVersions = versionsData?.map(item => {
    return { value: item?.version_number, label: item?.device_type }
  }).filter(obj => obj?.value)

  console.log("VirsionList : ", VirsionList)

  const androidVersions = allVersions?.filter((item) => item?.value && item?.label === 1)
  const iosVersions = allVersions?.filter((item) => item?.value && item?.label === 2)
  const latestAndroidVersion = androidVersions?.reduce((acc, value) => { return (acc = acc > value.value ? acc : value.value) }, 0);
  const latestIOSVersion = iosVersions?.reduce((acc, value) => { return (acc = acc > value.value ? acc : value.value) }, 0);



  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      // dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      // dispatch(updateIssueManagementStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      // dispatch(updateIssueManagementStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(fetchVirsionData());
  }
  // const labelId = `enhanced-table-checkbox-${row._id}`;

  // const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell className={classes.tableCellRoot}>{row?.device_type === 1 ? 'ANDROID' : 'IOS'}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row?.version_number}</TableCell>
      <TableCell className={classes.tableCellRoot}>{moment(row?.created_at * 1000).format('DD MMM YYYY hh:mm A')}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row?.is_force_update == 1 ? 'Forced Update' : 'Optional Update'}</TableCell>
      {(row?.version_number == latestAndroidVersion && row?.device_type == 1) || (row?.version_number == latestIOSVersion && row?.device_type == 2)
        ? <TableCell><Button variant="contained" onClick={() => {onUserDelete(row)} } color="primary">Remove</Button></TableCell>
        // ? <TableCell><Button variant="contained" onClick={() => {dispatch(deleteVersion(row?._id)); dispatch(fetchVirsionData()); refreshContactList()} } color="primary">Remove</Button></TableCell>
        : <TableCell>{' '}</TableCell>}
    </TableRow>
  );
};

export default React.memo(UserListRow);
