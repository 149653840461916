import { ADD_SUBADMIN, GET_SUBADMIN, GET_SUBADMIN_DETAILS } from '../../@jumbo/constants/ActionTypes';
  
  const INIT_STATE = {
    users: [],
    currentUser: null,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_SUBADMIN: {
        return {
          ...state,
          users: [action.payload, ...state.users],
        };
      }
      case GET_SUBADMIN: {
        return {
          ...state,
          users: action.payload,
        };
      }
      case GET_SUBADMIN_DETAILS: {
        return {
          ...state,
          currentUser: action.payload,
        };
      }
      default:
        return state;
    }
  };
  