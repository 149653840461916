import { GET_END_USERS, GET_ENDUSERS_DETAILS, GET_EXPORT_ENDUSERS } from '../../@jumbo/constants/ActionTypes';
  
  const INIT_STATE = {
    users: [],
    currentUser: null,
    export:null,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_END_USERS: {
        return {
          ...state,
          users: action.payload,
        };
      }
      case GET_ENDUSERS_DETAILS: {
        return {
          ...state,
          currentUser: action.payload,
        };
      }
      case GET_EXPORT_ENDUSERS: {
        return {
          ...state,
          export: action.payload,
        };
      }
      default:
        return state;
    }
  };
  