import React, { useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Edit, Visibility, Delete } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';
// import { sentMailToUser } from 'redux/actions/Users';
import { getCompaniesList, updateCompaniesStatus, updateCompaniesMakeDefault } from 'redux/actions/Companies';
import { Activate, Deactivate } from '@jumbo/constants/TableLableConstant';
import { GET_COMPANIESFORTABLE } from '@jumbo/constants/ActionTypes'
import moment from 'moment';
import { useSelector } from 'react-redux';
import { isTemplateMiddle } from 'typescript';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    fontWeight: '300',
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  switch: {
    '& .Mui-disabled': {
      color: theme.palette.secondary.main
    },
  },
  longTitleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    fontWeight: '300',
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '170px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    // { action: 'edit', label: 'Edit', icon: <Edit /> },
  ];
  if (user.status === 1) {
    actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }
  // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getCompaniesListParams }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getCompaniesListParams;
  const { users } = useSelector(({ COMPANIES }) => COMPANIES);
  const classes = useStyles();
  const [makeDefault, setMakeDefault] = useState(!row?.make_default_followed)
  const dispatch = useDispatch();
  // companiesListData = [];

  useEffect(() => {
    setMakeDefault(!row?.make_default_followed);
  }, [row?.make_default_followed]);

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      // dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateCompaniesStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateCompaniesStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  function refreshContactList() {
    dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  }

  const toggleChecked = () => {
    if (makeDefault === false) {
      setMakeDefault(true);
    }
    else {
      setMakeDefault(false);
    }

 
    // dispatch({ type: GET_COMPANIESFORTABLE, payload: list });

    makeDefaultOptionSelect();
  };

  function makeDefaultOptionSelect() {
    
    const companiesListData = users?.data?.map(item => {

      if (item?._id == row?._id) {
        item = { ...item, make_default_followed: !row?.make_default_followed }
      }
      return item;
    })
    const list = { ...users, data: companiesListData }

    dispatch(updateCompaniesMakeDefault({ _id: row._id, make_default_followed: makeDefault }, list))

    // setTimeout(() => {
    // dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
    // }, 500);
    // console.log("makeDefault 2",makeDefault)
  }

  // const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {/* key={row._id} */}
      <TableCell padding="checkbox"></TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="p">
          <img src={row?.logo ? row?.logo : process.env.PUBLIC_URL + '/placeholder1.jpeg'} style={{ maxHeight: '50px', maxWidth: '50px' }} />
          {/* : <img src={process.env.PUBLIC_URL + '/placeholder1.jpeg'} style={{maxHeight: '50px', maxWidth: '50px'}} /> } */}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        {/* id={labelId} */}
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="p">
          {row?.ticker}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.longTitleRoot} component="div" variant="p">
          {moment(row?.created_at * 1000).format('DD MMM YYYY hh:mm A')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="p">
          {row?.status === 1 ? `Active` : 'Inactive'}
        </Typography>
      </TableCell>
      <TableCell>
        <Switch
          // trackColor={{ true: 'blue', false: 'red' }}
          checked={!makeDefault}
          // style = {{color: 'blue'}}
          className={classes.switch}
          // disabled={!edit || !authUser?.fm_module_access[4]?.is_enable}
          // onChange={()=>{makeDefault === false ? true : false; makeDefaultOptionSelect()}}
          onChange={() => { toggleChecked() }}
        // makeDefaultOptionSelect();
        // dispatch(updateCompaniesMakeDefault({ _id: row._id, make_default_followed: (makeDefault == '0' ? '1') || (makeDefault == '1' ? '0')}, refreshContactList))
        />
      </TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu onItemClick={onUserMenuClick} items={userActions} TriggerComponent={<Edit />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
