import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import BugReportIcon from '@material-ui/icons/BugReport';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ ISSUEMANAGEMENT }) => ISSUEMANAGEMENT);
  const { name, status, service_type_details } = currentUser;
  console.log('issue', currentUser);
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
      

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot} style={{
fontSize: 14,
letterSpacing: 0.25,

textOverflow: 'ellipsis',
maxWidth:'350px',
display: 'block',
overflow: 'hidden',
whiteSpace: 'nowrap',}}>{name?.toUpperCase()}</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" className={classes.titleRoot1} color="common.dark">
          Issue Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BugReportIcon />
          <Typography className={classes.titleRoot1}>Name :</Typography>
          <Typography className={classes.titleRoot2}>{name}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BuildIcon />
          <Typography className={classes.titleRoot1}>Service Type :</Typography>
          <Typography className={classes.titleRoot2}>{service_type_details?.name}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status ===1 ? <CheckCircleOutline /> : <Block />}
          <Typography className={classes.titleRoot1}>Status :</Typography>
          <Typography className={classes.titleRoot2}>{status===1 ? 'Active' : 'Inactive'}</Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
