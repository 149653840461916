import { GET_VIRSION, DELETE_VERSION } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  VirsionList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VIRSION: {
      return { ...state, VirsionList: action.payload };
    }
    case DELETE_VERSION: {
      return {
        ...state,
        VirsionList: state.VirsionList.filter(user => user.id !== action.payload),
      };
    }
    default:
      return state;
  }
};
