import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { requiredMessage, emailNotValid } from '@jumbo/constants/ErrorMessages';
import { isValidEmail } from '@jumbo/utils/commonHelper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authLogo: {
    maxWidth: '70px',
    width: '5rem',
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    "& .Mui-error": {
      color: 'red'
    },
    "& .MuiFormHelperText-root": {
      color: 'red'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [password, setPassword] = useState(localStorage.getItem('password'));
  const [emailValidation, setEmailValidation] = useState('');
  const [passwordValidation, setPasswordValidation] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const passwordField = useRef();
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  console.log("CurrentAuthMethod",CurrentAuthMethod)

  function emailCheckValidation(email) {
    const mailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      setEmailValidation(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailValidation(emailNotValid);
      return false;
    } else {
      setEmailValidation('');
      return true;
    }
  }

  function passwordCheckValidation(password) {
    if (password === '') {
      setPasswordValidation(requiredMessage);
      return false;
    } else {
      setPasswordValidation('');
      return true;
    }
  }

  const onSubmit = () => {
    let emailValidation = emailCheckValidation(email);
    let pswVal = passwordCheckValidation(password);

    if (emailValidation && pswVal) {
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
      }
      dispatch(AuhMethods[method].onLogin({ email, password }));
    }
    // dispatch(AuhMethods[method].onLogin({ email, password }));
  };

  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      passwordField.current.focus();
      onSubmit();
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={process.env.PUBLIC_URL + '/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={process.env.PUBLIC_URL + '/images/logo.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => {
                setEmail(event.target.value);
                setEmailValidation('')}}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              onKeyDown={handleEnterKey}
              className={classes.textFieldRoot}
              helperText={emailValidation}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => {
                setPassword(event.target.value);
                setPasswordValidation('')}}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              onKeyDown={handleEnterKey}
              className={classes.textFieldRoot}
              helperText={passwordValidation}
              id="password"
              inputRef={passwordField}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            {/* <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={<Checkbox name="checkedA" onClick={() => setRememberMe(rememberMe ? false : true)} checked={rememberMe} />}
              label="Remember me"
            /> */}
            <Box></Box>
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgot-password">
                <IntlMessages id="appModule.forgotPassword" />
              </NavLink>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.signIn" />
            </Button>

          </Box>
        </form>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
