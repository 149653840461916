import axios2 from 'axios';

export default axios2.create({
     baseURL: `https://www.alphavantage.co/`,
  // baseURL: `https://finnhub.io/api/v1/`, //YOUR_API_URL HERE
//   baseURL: `https://node.inheritxdev.in/underwriter-dev/v1/`, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
