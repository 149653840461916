import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  companyHeadCell: {
    // marginRight:'15px',
    maxWidth: '70px',
  },
  actionHeadCell: {
    // marginRight:'15px',
    maxWidth: '30px',
  },
}));

const headCells = [
  { id: 'logo', numeric: false, disablePadding: false, label: 'Logo' },
  { id: 'name', numeric: false, disablePadding: true, label: 'Company Name' },
  { id: 'ticker', numeric: false, disablePadding: false, label: 'Ticker' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created at' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'make_default_followed', numeric: false, disablePadding: false, label: 'Make Default' },
 
];

function UserTableHead({ classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
          // className={headCell.id == 'companyName' ? classes.companyHeadCell : classes.actionHeadCell}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
                          disabled={headCell.id ==='logo' ? true : false}
                          hideSortIcon={headCell.id ==='logo' ? true : false}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

UserTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default React.memo(UserTableHead);
