import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { stringOnly } from '@jumbo/constants/ValidationRegex';
import { isValidEmail } from '@jumbo/utils/commonHelper';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { emailNotValid, lettersOnlyMessage, requiredMessage } from '@jumbo/constants/ErrorMessages';
// import { updateUser } from 'redux/actions/Users';
import { alpha } from '@material-ui/core/styles';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import {emptySpace} from '@jumbo/constants/ValidationRegex'
// import { addNewIssueManagement, getIssueManagement, updateIssueData } from 'redux/actions/IssueManagement';
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import { createNewSubAdmin, getSubAdminList } from 'redux/actions/SubAdminManagement'
// import Select from 'react-select';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      minWidth: '700px',
  },
  },
  root: {
    "& .Mui-error": {
      color: 'red'
    },
    "& .MuiFormHelperText-root": {
      color: 'red'
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      color: theme.palette.text.primary
    },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
},
assignInventorySiteView: {
  zIndex: 99,
  // minHeight: '40px',
  minWidth: '200px',
  fontSize: '14px',
  '& .chip':{
    background:`${theme.palette.primary.main} !important`
  },
  '& .highlightOption':{
    background:`${theme.palette.primary.main} !important`
  },
'& input':{
  fontSize:'16px',
  paddingLeft:'5px',
  minHeight: '28px',
},
'& .optionContainer':{
  border: '1px solid #ccc',
  borderRadius: '4px',
  display: 'block',
  margin: 0,
  // maxHeight: '125px',
  overflowY: 'auto',
  padding: 0,
},
},
errText: {
  color: 'red',
  marginLeft: '14px',
  marginRight: '14px',
  fontSize: '0.75rem',
  marginTop: '3px',
  textAlign: 'left',
  fontFamily: 'Normal',
  fontWeight: 400,
  lineHeight: 1.66,
},
}));


const AddEditUser = ({ open, onCloseDialog, getSubAdminManagementParams }) => {
  const classes = useStyles();
  // const { users } = useSelector(({ SERVICETYPE }) => SERVICETYPE);
  const currentUser = useSelector(({ SUBADMINMANAGEMENT }) => SUBADMINMANAGEMENT.currentUser);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, subAdmin } = getSubAdminManagementParams;
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const p_color=window.localStorage.getItem('p_c') ?? '#FC8119'
  const s_color=window.localStorage.getItem('s_c') ?? '#F0511E'
  const t_color=window.localStorage.getItem('t_c') ?? '#3B1A45'
  const dispatch = useDispatch();
  const[status,setStatus]=useState(1)

  // useEffect(() => {
  //   if (currentUser) {
  //     // const [fName, lName] = splitName(currentUser);
  //     setFirstName(currentUser?.first_name);
  //     setStatus(currentUser?.status)
  //     setLastName(currentUser?.last_name);
  //     setEmail(currentUser.email);
  //   }
  // }, [currentUser]);

 
  const onSubmitClick = () => {
    
    if (!firstName) setFirstNameError(requiredMessage);
    else if (!firstName.match(stringOnly)) setFirstNameError(lettersOnlyMessage);

    if (!lastName) setLastNameError(requiredMessage);
    else if (!lastName.match(stringOnly)) setLastNameError(lettersOnlyMessage);

    if (!email) setEmailError(requiredMessage);
    else if (!isValidEmail(email)) setEmailError(emailNotValid);
  
    if(firstName && firstName.match(stringOnly) && lastName && lastName.match(stringOnly) && email && isValidEmail(email))
    {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const fname = capitalizeFLetter(firstName)
    const lname = capitalizeFLetter(lastName)
    const subAdminData = {
      email: email,
      first_name: fname,
      last_name: lname,
    }

    // if (currentUser) {
    //   dispatch(
    //     updateIssueData(currentUser?._id,subAdminData , () => {
    //       onCloseDialog();
    //       // dispatch(getIssueManagement(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
    //     }),
    //   );
    // } else {
      dispatch(createNewSubAdmin(subAdminData, () => {
          onCloseDialog();
          dispatch(getSubAdminList(orderBy, order, debouncedSearchTerm, 0, rowsPerPage, filterOptions, subAdmin));
        }),
      );
    // }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        Add New Sub Admin
      </DialogTitle>
      <DialogContent dividers>
      
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
              className={classes.root}
                fullWidth
                variant="outlined"
                label="First Name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value.slice(0, 20));
                  setFirstNameError('');
                }}
                helperText={firstNameError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AppTextInput
              className={classes.root}
                fullWidth
                variant="outlined"
                label="Last Name"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value.slice(0, 20));
                  setLastNameError('');
                }}
                helperText={lastNameError}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <AppTextInput
                className={classes.root}
                fullWidth
                variant="outlined"
                label="Email Address"
                value={email}
                onChange={e => {
                  setEmail(e.target.value.slice(0, 48));
                  setEmailError('');
                }}
                // disabled={currentUser ? true : false}
                helperText={emailError}
              />
            </Grid>
          </GridContainer>

        <Box display="flex" justifyContent="flex-end" mb={4} mt={5}>
          <Button variant='outlined' onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" style={{minWidth:'100px'}} color="primary" onClick={onSubmitClick}>
           ADD
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
          }

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
