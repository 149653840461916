import axios from 'axios';

export default axios.create({
  // baseURL: `https://finnhub.io/api/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://node.inheritxdev.in/underwriter-dev/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://node.inheritxdev.in/underwriter-qa/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://node.inheritxdev.in/underwriter-prod/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://underwriter-dev.inheritxdev.in/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://underwriter-prod.inheritxdev.in/v1`, //YOUR_API_URL HERE
  baseURL: `https://api.iamunderwriter.com/v1`, // YOUR_API_URL_HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
