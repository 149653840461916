import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { useSelector } from 'react-redux';
import EndUserManagement from './EndUserManagement/index';
import SubAdminManagement from './SubAdminManagement/index';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: '10px',
    
  },
});
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'User Management', isActive: true },
];
export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <PageContainer heading="User Management" breadcrumbs={breadcrumbs}>
      <Paper className={classes.root}>
        <Tabs value={value}  indicatorColor="primary" textColor="primary">
          <Tab label="End Users"  onClick={e=>setValue(0)}/>
          <Tab label="Sub Admins" onClick={e=>setValue(1)}/>
        </Tabs>
      </Paper>
      {value === 0 ? <EndUserManagement /> : null}
      {value === 1 ? <SubAdminManagement /> : null}
    </PageContainer>
  );
}
