import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { GET_SUBADMIN, FETCH_DATA_SUCCESS, GET_SUBADMIN_DETAILS } from '../../@jumbo/constants/ActionTypes';
import { addSubAdmin, subAdminUsersList, subAdminDetails, subAdminStatusChange } from '../../@jumbo/constants/ApiConstant';
import { history } from 'redux/store'
import { setAuthUser } from 'redux/actions/Auth';

// add new Sub Admin
export const createNewSubAdmin = (user, callbackFun) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post(addSubAdmin, user)
        .then(data => {
          if (data) {
            setTimeout(() => {
              dispatch(fetchSuccess('New sub admin created successfully.'));
            }, 2000);
            if (callbackFun) callbackFun(data.data);
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          dispatch(fetchError(error?.response?.data?.message));
        });
    };
  };

  
// get sub admin list
export const getSubAdminList = (sortBy, sort, searchTerm, page, limit, filterOptions, subAdmin, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(subAdminUsersList, {
        params: { sortBy: `${sortBy}:${sort}`, search: searchTerm, page: page + 1, limit: limit, status: filterOptions[0], user_type: subAdmin },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          // dispatch({type:FETCH_DATA_SUCCESS})
          dispatch({ type: GET_SUBADMIN, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Sub admin list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_SUBADMIN, payload: [] });
        } 
       else if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } 
        
        else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

  // set current user for view tab
  export const setCurrentSubAdminManagement = user => {
    return dispatch => {
      dispatch({ type: GET_SUBADMIN_DETAILS, payload: user });
    };
  };


export const getSubAdminDetails = (userId, callbackFun) => {
  return dispatch => {
      dispatch(fetchStart());
      axios
        .get(subAdminDetails + userId)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: GET_SUBADMIN_DETAILS, payload: data.data.data });
  
            if (callbackFun) callbackFun();
          } else {
            dispatch(fetchError('There was something issue in responding server.'));
          }
        })
        .catch(error => {
          if (error?.response?.data?.message === 'Sub admin List not found.') {
            dispatch(fetchError(error?.response?.data?.message));
            dispatch({ type: GET_SUBADMIN_DETAILS, payload: [] });
          } else {
            dispatch(fetchError(error?.response?.data?.message));
          }
        });
  };
};

export const updateSubAdminManagementStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(subAdminStatusChange, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('Sub admin status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if(error?.response?.status===401 ){
          dispatch(setAuthUser(null));
          history.push('/signin')
        } else{
          dispatch(fetchError(error?.response?.data?.message));}
      });
  };
};

// export const updateSubAdmin = (id,user, callbackFun) => {
//   return dispatch => {
    
//     dispatch(fetchStart());
//     axios
//       .put(updateIssueApi+id, user)
//       .then(data => {
//         setTimeout(() => {
//           dispatch(fetchSuccess('Sub admin updated successfully .'));
//         }, 2000);
//           if (callbackFun) callbackFun();
//       })
//       .catch(error => {
//         if(error?.response?.status===401 ){
//           dispatch(setAuthUser(null));
//           history.push('/signin')
//         } 
//         else {
//           dispatch(fetchError(error?.response?.data?.message));
//         }
//       });
//   };
// };