import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
// import  from '@material-ui/core/Box';
import { Box, CircularProgress, InputBase } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { alpha } from '@material-ui/core/styles';
import { emptySpace } from '@jumbo/constants/ValidationRegex'
import { capitalizeFLetter } from '@jumbo/utils/commonHelper';
import SearchIcon from '@material-ui/icons/Search';
import { fetchCompanies, fetchCompaniesProfile, addCompaniesToList, getCompaniesList, getCompaniesListForMatchInAddCompanies } from '../../../../redux/actions/Companies';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
//import TableItem from './TableItem';
import Table from '@material-ui/core/Table';
import { GET_COMPANIES } from '@jumbo/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions/Common';
import { useDebounce } from '@jumbo/utils/commonHelper';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      minWidth: '400px',
    },
    //   '& .MuiDialog-paper': {
    //     // margin: '32px',
    //     // position: 'relative',
    //     overflow: 'inherit',
    //     // backgroundColor: '#FFFFFF',
    // },
    //   '& .MuiDialogContent-root': {
    //     // flex: '1 1 auto',
    //     // padding: '8px 24px',
    //     overflow: 'inherit',
    //     // webkitOverflowScrolling: 'touch',
    // },
  },
  root: {
    "& .Mui-error": {
      color: 'red'
    },
    "& .MuiFormHelperText-root": {
      color: 'red'
    },
    "& .MuiFormLabel-root": {
      color: '#909090'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary
    },
    dialogTitleRoot: {
      '& .MuiTypography-h6': {
        fontSize: 16,
        color: theme.palette.common.dark,
      },
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.common.dark, 0.12),
      },
    },
  },
  assignInventorySiteView: {
    zIndex: 99,
    // minHeight: '40px',
    minWidth: '200px',
    fontSize: '14px',
    '& .chip': {
      background: `${theme.palette.primary.main} !important`
    },
    '& .highlightOption': {
      background: `${theme.palette.primary.main} !important`
    },
    // '& .multiSelectContainer li':{
    //     '&:hover': {
    //       background:`${theme.palette.primary.main} !important`,
    //    },   
    // },
    // '& li':{
    //   fontSize:'14px',
    //   '&:hover': {
    //     background:`${theme.palette.primary.main} !important`,
    //  },
    // },
    '& input': {
      fontSize: '16px',
      paddingLeft: '5px',
      minHeight: '28px',
    },
    '& .optionContainer': {
      border: '1px solid #ccc',
      borderRadius: '4px',
      display: 'block',
      margin: 0,
      // maxHeight: '125px',
      overflowY: 'auto',
      padding: 0,
    },
  },
  // dropdownMenu: {

  // }
  errText: {
    color: 'red',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Normal',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  scrollbarRoot: {
    height: 340,
  },
  noRecord: {
    textAlign: 'center',
    height: '250px',
    fontSize: 16,
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
    },
    border: 'none',
  },
  tableCellRoot: {
    textAlign: 'center',
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
      maxWidth: '70px',
    },
  },
  tableRowRoot: {
    '& .MuiTableCell-root': {
      fontWeight: 300,
    }
  },
  cardContentRoot: {
    padding: '0 !important',
    // minWidth: '1000px',
  },
  searchRoot: {
    borderStyle: 'ridge',
  },
  inputSearch: {
    [theme.breakpoints.up('md')]: {
      width: 485,
    },
    // width: '100%',
    marginLeft: '10px',
    // minWidth: '215px',
    minHeight: '35px',
  },
  magnifier: {
    marginTop:'5px',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
    // minHeight:'300px'
  },
}));


const AddEditUser = ({ open, onCloseDialog, getIssueManagementParams, changesCompanies, setChangesCompanies }) => {
  const classes = useStyles();
  const { companiesStore } = useSelector(({ COMPANIES }) => COMPANIES);
  // const users = useSelector(({ COMPANIES }) => COMPANIES.users);
  const users = useSelector(({ COMPANIES }) => COMPANIES.companiesTableListForAddCompanies);
  // const currentUser = useSelector(({ COMPANIES }) => COMPANIES.currentUser);
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getIssueManagementParams;
  const [serviceTypeValueError, setServiceTypeValueError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [serviceTypeValue, setServiceTypeValue] = useState('');
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueError, setSearchValueError] = useState('');
  const [symbolValue, setSymbolValue] = useState('');
  const [refreshFetchComapniesList, setRefreshFetchComapniesList] = useState(false);
  const [usersFetched, setUsersFetched] = useState(0);
  const debouncedValue  = useDebounce(searchValue, 300);
  const [addButtonDisable, setAddButtonDisable] = useState([])
  const [checkingData, setCheckingData] = useState(false)
  const [disableDoubleClick, setDisableDoubleClick] = useState([])
  // const [isLoading, setIsLoading] = useState(false);
  // const [searchedData,setSearchedData]= useState('debouncedSearchPagevalidation')
  const p_color = window.localStorage.getItem('p_c') ?? '#FC8119'
  const s_color = window.localStorage.getItem('s_c') ?? '#F0511E'
  const t_color = window.localStorage.getItem('t_c') ?? '#3B1A45'
  const dispatch = useDispatch();
  const [status, setStatus] = useState(1);
  // tableListingTicker = [];
  console.log("companiesStore",companiesStore)
  // const tableListingTicker = users?.data?.map((item)=>item?.ticker);
  // if(companiesStore?.length)
  // {
    // const tableListingCheckingCompanyAddOrNot = companiesStore?.map((item)=>item?.is_added);
  // }
  // console.log("tableListingTicker",tableListingTicker)
  // console.log("tableListingCheckingCompanyAddOrNot",tableListingCheckingCompanyAddOrNot)
  // console.log("users",users)

  const handleCancelDialogue = ()=>{
    if (changesCompanies == true)
    {
      dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
    }
          dispatch({type:GET_COMPANIES, payload:null})
          onCloseDialog();
  }
console.log("usersFetched",usersFetched)
  useEffect(() => {
    // Do fetch here...
    
    if(debouncedValue){
      dispatch(fetchCompanies(searchValue, ()=>{
        // setRefreshFetchComapniesList(true)
        setUsersFetched(2);
      }))
    }
    // setRefreshFetchComapniesList(false)
    // if(companiesStore?.length)
    // {
    //   setIsLoading(false);
    // }
    // Triggers when "debouncedValue" changes
  }, [debouncedValue])

  useEffect(() => {
    setChangesCompanies(false);
    // setSearchedData('debouncedSearchPagevalidation')
    // dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, '', -1, filterOptions));
    // dispatch(getCompaniesListForMatchInAddCompanies(orderBy, order, debouncedSearchTerm, '', -1, filterOptions));
    return () => {
      dispatch({type:GET_COMPANIES,payload:null})
      // if(addButtonDisable?.length)
      // {
        setAddButtonDisable([])
      // }
    };
  }, []);

  // const callingCompaniesTableListing = ()=> {
  //   setTimeout(() => {
  //     dispatch(getCompaniesListForMatchInAddCompanies(orderBy, order, debouncedSearchTerm, '', -1, filterOptions))
  //     // dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  //   }, 1500);
  //   // dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, '', -1, filterOptions))
  // }
  
  function throttle(fn, delay) {
    console.log("line 1")
    let run = false
      console.log("line 2")
      if (!run) {
        console.log("line 3")
        run = true
        fn()
        setTimeout( () => run = false, delay)
      }
  }


  const onCloseDialogWOButton = ()=>{
    if(changesCompanies == true){
      dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
    }
    onCloseDialog();
  }

  console.log("addButtonDisable",addButtonDisable)
  return (
    <Dialog open={open} onClose={()=>{onCloseDialogWOButton()}} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {'Add New Company'}
      </DialogTitle>
      <DialogContent dividers>

        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <Hidden smDown>
                <Box pr={3} display="flex" className={classes.searchRoot}>
                  <InputBase
                    placeholder={'Company or Ticker'}
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchValue}
                    onChange={e => {
                      setSearchValue(e.target.value);
                      setSearchValueError('');
                      setUsersFetched(1);
                      // setCheckingData(true)
                      // setCheckingData(true)
                      // setRefreshFetchComapniesList(true)
                      // setUsersFetched(true);
                      // fetchCompaniesList();
                      // if(e.target.value == ''){
                      //   dispatch(fetchCompanies(e.target.value)) 
                      // }
                    }}
                    className={classes.inputSearch} />
                  <SearchIcon className={classes.magnifier} />
                </Box>
              </Hidden>
            </Grid>

            <Grid item xs={12} sm={12}>
              <CmtCard>
                <CmtCardContent className={classes.cardContentRoot}>
                  <PerfectScrollbar className={classes.scrollbarRoot}>
                    <Box>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow className={classes.tableHead}>
                            <TableCell className={classes.tableCellRoot}>Company Name</TableCell>
                            {/* <TableCell className={classes.tableCellRoot}>Identify</TableCell> */}
                            <TableCell className={classes.tableCellRoot}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {usersFetched == 1 && searchValue ?  
                                                <TableRow>
                                                <TableCell colSpan={7} rowSpan={10} className={classes.noRecord}>   
                                                <CircularProgress />
                                                </TableCell>
                                              </TableRow>
                                              : (
                          <>
                          {searchValue && companiesStore?.length ? (
                            companiesStore?.map((row, index) => (
                              <TableRow className={classes.tableRowRoot}>
                                {console.log("row",row)}
                                <TableCell className={classes.tableCellRoot}>
                                  <Typography>{row?.name}</Typography>
                                  <Typography style={{fontSize:'10px'}}>{row?.symbol}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellRoot}>
                                {/* (tableListingTicker?.includes ?? [])(row?.["1. symbol"]) */}
                                {/* disabled={tableListingTicker?.includes(row?.["1. symbol"]) ? true : false} */}
                                {/* disabled={tableListingTicker?.includes(row?.symbol) ? true : false} */}
                                  <Button variant="contained" style={{ minWidth: '100px' }} color="primary" disabled={disableDoubleClick?.includes(row?.symbol) || row?.is_added===true || addButtonDisable?.includes(row?.symbol) ? true : false} onClick={()=>{throttle(()=>dispatch(addCompaniesToList( {"name": row?.name, "ticker":row?.symbol}, (data)=>{setAddButtonDisable([...addButtonDisable, data])} )), setChangesCompanies(true), setDisableDoubleClick([...disableDoubleClick, row?.symbol]), 1000)}}>{disableDoubleClick?.includes(row?.symbol) || row?.is_added===true || addButtonDisable?.includes(row?.symbol) ? 'Added' : 'Add'}</Button>
                                  {/* onClick={()=>{dispatch(addCompaniesToList({"name": row?.name, "ticker":row?.symbol}, (data)=>{setAddButtonDisable([...addButtonDisable, data])})); setChangesCompanies(true)}} */}
                                  {/* callingCompaniesTableListing(); */}
                                  {/* {tableListingTicker?.includes(row?.["1. symbol"]) ? 'Added' : 'Add'} */}
                                  {/* dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, '', -1, filterOptions)); */}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={7} rowSpan={10} className={classes.noRecord}>   

                                { !searchValue ? 'Please search company via ticker to see the result' 
                                : usersFetched == 2 && searchValue ? 'There are no records found.' : null}


                              </TableCell>
                            </TableRow>
                          )}
                          </>
                        )}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </CmtCardContent>
              </CmtCard>
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button variant='contained' color="primary" onClick={handleCancelDialogue}>Close</Button>
          {/* variant='outlined' */}
          {/* <Box ml={2}>
            {/* <Button variant="contained" style={{minWidth:'100px'}} color="primary">
           {'ADD'}
            </Button> */}
          {/* </Box> */}
        </Box>

      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
