import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 9999,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    webkitBackdropFilter: 'blur(1px)',
    backdropFilter: 'blur(1px)',
  },
}));

const PageLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.circularProgressRoot} id="pageLoaderCircularIcon">
      <CircularProgress />
    </Box>
  );
};

export default PageLoader;
