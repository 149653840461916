import React from 'react';
import AuthWrapper from './@jumbo/components/Common/authComponents/AuthWrapper';

function FMAdminDemo() {
  const wrapperVariant = 'default';
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height:'65vh'}}>
      <h1>Coming Soon...</h1>
    </div>
  );
}

export default FMAdminDemo;
