import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import BugReportIcon from '@material-ui/icons/BugReport';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { makeStyles } from '@material-ui/core/styles';
import { Block, CheckCircleOutline,  } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
import { getCompaniesList, updateCompaniesStatus, updateCompaniesMakeDefault, setCurrentCompanies, getCompaniesListForMatchInAddCompanies, getCompaniesListForViewDialogue } from 'redux/actions/Companies';
import { GET_COMPANIES_DETAILS } from '../../../../@jumbo/constants/ActionTypes';
import { useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import BusinessIcon from '@material-ui/icons/Business';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

// const useStyles = makeStyles(theme => ({
//   switch: {
//     '& .Mui-disabled': {
//       color: theme.palette.secondary.main,
//     },
//   },
// }));

const UserDetailView = ({ open, onCloseDialog, getCompaniesListParams }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ COMPANIES }) => COMPANIES);
  const users = useSelector(({ COMPANIES }) => COMPANIES.users);
  const { _id, name, status, ticker, make_default_followed, logo, created_at } = currentUser;
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions } = getCompaniesListParams;
  const [makeDefault, setMakeDefault] = useState(!make_default_followed);
  const dispatch = useDispatch();
  console.log('currentUser', currentUser?._id);
  console.log("users",)


  useEffect(() => {
   
    // if( users?.data?.map(item => item?.id == currentUser?._id))
    // {
    //   console.log("currentUser?.make_default_followed 1",currentUser?.make_default_followed)
    //   currentUser?.make_default_followed = item?.make_default_followed;
    //   console.log("currentUser?.make_default_followed 2",currentUser?.make_default_followed)
    // }

    setMakeDefault(!make_default_followed);
    // return () => {
    //   // dispatch({type:GET_COMPANIES_DETAILS, payload:data.data})
    // };
  }, []);

  const toggleChecked = () => {
    if(makeDefault === false)
    {
      setMakeDefault(true);
    }
    else
    {
      setMakeDefault(false);
    }    
    makeDefaultOptionSelect();
  };


  async function makeDefaultOptionSelect() {
   
    const companiesListData = users?.data?.map(item => {

      if (item?._id == currentUser?._id) {
        item = { ...item, make_default_followed: !item?.make_default_followed }
      }
      return item;
    })
    const list = { ...users, data: companiesListData }
    let test = {...currentUser, make_default_followed:!currentUser?.make_default_followed}
    dispatch({type:GET_COMPANIES_DETAILS, payload:test})
    await dispatch(updateCompaniesMakeDefault({ _id: _id, make_default_followed: makeDefault}, list))
    // setTimeout(() => {
    //   dispatch(getCompaniesListForViewDialogue(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
    // }, 1000);
  }

  // function refreshContactList() {
  //   dispatch(getCompaniesList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions));
  // }

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <img src={logo ? logo : process.env.PUBLIC_URL + '/placeholder1.jpeg'} style={{maxHeight: '50px', maxWidth: '50px'}} /> 
              {/* : <img src={} style={{maxHeight: '50px', maxWidth: '50px'}} /> } */}
            {/* <Typography className={classes.titleRoot}>{'Company Details'}</Typography> */}
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box px={6} py={5}>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <BusinessIcon />
          <Typography className={classes.titleRoot1}>Company Name :</Typography>
          <Typography className={classes.titleRoot2}>{name}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <LabelImportantIcon />
          <Typography className={classes.titleRoot1}>Ticker :</Typography>
          <Typography className={classes.titleRoot2}>{ticker}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          {status === 1 ? <CheckCircleOutline /> : <Block />}
          <Typography className={classes.titleRoot1}>Status :</Typography>
          <Typography className={classes.titleRoot2}>{status === 1 ? 'Active' : 'Inactive'}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EventIcon />
          <Typography className={classes.titleRoot1}>Created at :</Typography>
          <Typography className={classes.titleRoot2}>{moment(created_at * 1000).format('DD MMM YYYY hh:mm A')}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
        {makeDefault === true ? <ToggleOffIcon /> : <ToggleOnIcon />}
          <Typography className={classes.titleRoot1}>Make Default Followed :</Typography>
          <Switch
          checked={!makeDefault}
          className={classes.switch}
          onChange={()=>{toggleChecked()}}
          // makeDefaultOptionSelect();
          // dispatch(updateCompaniesMakeDefault({ _id: row._id, make_default_followed: (makeDefault == '0' ? '1') || (makeDefault == '1' ? '0')}, refreshContactList))
        />
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
