import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AppContext from '../contextProvider/AppContextProvider/AppContext';
import globalStyles from '../../../theme/GlobalCss';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../constants/AppConstants';
import { LAYOUT_TYPES } from '../../constants/ThemeOptions';
import VerticalDefault from './VerticalLayouts/VerticalDefault';
import VerticalMinimal from './VerticalLayouts/VerticalMinimal';
import MinimalNoHeader from './VerticalLayouts/MinimalNoHeader';
import ModernSideBar from './VerticalLayouts/ModernSidebar';
import HorizontalDefault from './HorizontalLayouts/HorizontalDefault';
import HorizontalDark from './HorizontalLayouts/HorizontalDark';
import HorizontalMinimal from './HorizontalLayouts/HorizontalMinimal';
import HorizontalTopMenu from './HorizontalLayouts/HorizontalTopMenu';

const useStyles = makeStyles(() => ({
  circularProgressRoot: {

  //   '& .MuiCircularProgress-colorPrimary': {
  //     color: 'green !important',
  // },
  // '& .MuiCircularProgress-root': { 
  //     left: '43%',
  //     position: 'absolute', 
  //     top: '44vh',
  //     background: 'rgba(0, 0, 0, 0.07)',
  // },

    // backgroundColor: 'rgba(255, 255, 255, 0.4)',
    // webkitBackdropFilter: 'blur(5px)',
    // backdropFilter: 'blur(5px)',
    position: 'absolute',
    left: 0,
    top: 0,
    // bottom: 0,
    // right: 0,
    zIndex: 9999,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'rgba(0, 0, 0, 0.07)',
    // content:'',
    // display:block,
    // position:'absolute',
    // left:'48%', top:'40%',
    // width:'40px', height:'40px',
    // borderStyle:solid,
    // borderColor:black,
    // borderTopColor:transparent,
    // borderWidth: 4px,
    // borderRadius:50%,
    // // -webkit-animation: spin .8s linear infinite,
    // animation: spin .8s linear infinite,

    // position: 'fixed',
    // width: '100%',
    // left: '0', right: '0', top: '0', bottom: '0',
    // backgroundColor: 'rgba(255,255,255,0.7)',
    // zIndex: 9999,
    // display: 'none',


    // flex: 1, 
    // backgroundColor: 'rgba(0,0,0,0.6)',
  },
}));

const AppLayout = ({ children }) => {
  const [showLayoutLoader, setLayoutLoader] = useState(true);
  const { layout } = useContext(AppContext);
  const { loadUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  globalStyles();

  useEffect(() => {
    dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
    setLayoutLoader(false);
  }, []);

  if (showLayoutLoader || !loadUser) {
    return (
      <div id="loaderProcess" className={classes.circularProgressRoot}>
        <CircularProgress />
      </div>
    );
  }

  if (['/signin', '/signup', '/forgot-password'].includes(location.pathname)) {
    return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
  }

  switch (layout) {
    case LAYOUT_TYPES.VERTICAL_DEFAULT: {
      return <VerticalDefault children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MINIMAL: {
      return <VerticalMinimal children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MINIMAL_NO_HEADER: {
      return <MinimalNoHeader children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MODERN_SIDEBAR: {
      return <ModernSideBar children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_DEFAULT: {
      return <HorizontalDefault children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_DARK: {
      return <HorizontalDark children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_MINIMAL: {
      return <HorizontalMinimal children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_TOP_MENU: {
      return <HorizontalTopMenu children={children} />;
    }
    default:
      return <VerticalDefault children={children} />;
  }
};

export default AppLayout;
