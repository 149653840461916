import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import BugReportIcon from '@material-ui/icons/BugReport';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
import FaceIcon from '@material-ui/icons/Face';
import EmailIcon from '@material-ui/icons/Email';

const UserDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentUser } = useSelector(({ SUBADMINMANAGEMENT }) => SUBADMINMANAGEMENT);
  const { first_name, last_name, email, status } = currentUser;
  console.log("currentUser",currentUser)

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
            <Typography className={classes.titleRoot}>{'Sub Admin Details'}</Typography>
              <Box ml={1}></Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      
      <Box px={6} py={5}>
      
      {/* <Box display="flex"> */}
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <FaceIcon />
          <Box>
          <Box display="flex">
          <Typography className={classes.titleRoot1}>First Name :</Typography>
          <Typography className={classes.titleRoot2}>{first_name}</Typography>
          </Box>
          <Box display="flex" mt={5}>
          <Typography className={classes.titleRoot1}>Last Name :</Typography>
          <Typography className={classes.titleRoot2}>{last_name}</Typography>
          </Box>
          </Box>
        {/* </Box> */}

        {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }} ml={25}>
          <FaceIcon />
          <Typography className={classes.titleRoot1}>Last Name :</Typography>
          <Typography className={classes.titleRoot2}>{last_name}</Typography>
        </Box> */}
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Typography className={classes.titleRoot1}>Email :</Typography>
          <Typography className={classes.DescriptionNote}>{email}</Typography>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          {status ===1 ? <CheckCircleOutline /> : <Block />}
          <Typography className={classes.titleRoot1}>Status :</Typography>
          <Typography className={classes.titleRoot2}>{status===1 ? 'Active' : 'Inactive'}</Typography>
        </Box>

      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
