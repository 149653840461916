import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Edit, Visibility, Delete } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
// import { sentMailToUser } from 'redux/actions/Users';
import { getSubAdminList, updateSubAdminManagementStatus } from 'redux/actions/SubAdminManagement';
import { Activate, Deactivate } from '@jumbo/constants/TableLableConstant';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    fontWeight: '300',
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  emailField: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    fontWeight: '300',
    fontSize: 14,
    letterSpacing: 0.25,
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  DescriptionNote: {
    letterSpacing: 0.25,
    maxHeight:'130px',
    maxWidth:'330px',
    // minHeight:'130px',
    // display: 'block',
    overflow: 'auto',
    wordWrap: 'break-word',
    fontWeight: '300',
    marginLeft: '5px',
    fontSize: 14,
    textOverflow: 'ellipsis',
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    // { action: 'edit', label: 'Edit', icon: <Edit /> },
  ];

  if (user.status === 1) {
    actions.push({ action: 'suspend', label: Deactivate, icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: Activate,
      icon: <CheckCircleOutline />,
    });
  }
  // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  return actions;
};

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView, getSubAdminManagementParams }) => {
  const { orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, subAdmin } = getSubAdminManagementParams;

  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      // dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateSubAdminManagementStatus({ _id: row._id, status: 2 }, refreshContactList));
    } else if (menu.action === 'activate') {
      dispatch(updateSubAdminManagementStatus({ _id: row._id, status: 1 }, refreshContactList));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };
  function refreshContactList() {
    dispatch(getSubAdminList(orderBy, order, debouncedSearchTerm, page, rowsPerPage, filterOptions, subAdmin));
  }
  const labelId = `enhanced-table-checkbox-${row._id}`;

  const userActions = getUserActions(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {/* key={row._id} */}
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" scope="row" padding="none">
      {/* id={labelId} */}
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.first_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="p">
          {row?.last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.DescriptionNote} component="div" variant="p">
          {row?.email}
          {/* <Typography className={classes.DescriptionNote}>{'jeetmakvanainheritxsolutionsahmedabadsghighwayjeetmakvana@yopmailgmailyahoo.com'}</Typography> */}
        </Typography>
      </TableCell>
      <TableCell>
      <Typography className={classes.titleRoot} component="div" variant="p">
      {row?.status === 1 ? `Active` : 'Inactive'}
        </Typography>
        </TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu onItemClick={onUserMenuClick} items={userActions} TriggerComponent={<Edit />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
