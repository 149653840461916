import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SamplePage from './Pages/SamplePage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import ComingSoon from '../ComingSoon';
import IssueManagement from '../routes/modules/IssueManagement/index';
import Profile from './Apps/Profile/index';
import Settings from './modules/Settings/index'
import UserManagement from './modules/UserManagement/index'
import Companies from './modules/Companies/index'
import Unauthorized from './Pages/Unauthorized'

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if ((authUser?.user_type !== 1 && location?.pathname === '/usermanagement')) {
    return <Redirect to={'/unauthorized'} />;
  }
  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/companies'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/companies'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/companies" component={Companies} />

        <Route path="/signin" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <RestrictedRoute path="/profile" component={Profile} />
        <RestrictedRoute path="/settings" component={Settings} />
        <RestrictedRoute path="/usermanagement" component={UserManagement} />
        <Route path="/unauthorized" component={Unauthorized} />
        <Route path="*" component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
