import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { FETCH_DATA_SUCCESS, GET_VIRSION, DELETE_VERSION } from '../../@jumbo/constants/ActionTypes';
import { pushVirsionList, virsionList, virsionDelete} from '../../@jumbo/constants/ApiConstant';
import { history } from 'redux/store'
import { setAuthUser } from 'redux/actions/Auth';

export const fetchVirsionData = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios.get(virsionList, {params:{sortBy:'created_at:desc'}}).then(({ data }) => {
      dispatch({type:FETCH_DATA_SUCCESS})
      dispatch(fetchSuccess());
      dispatch({
        type: GET_VIRSION,
        payload: data.data,
      });
    }).catch(error => {
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
    });
  };
};

export const pushVirsionData = (data) => {
  return dispatch => {
    dispatch(fetchStart());
    axios.post(pushVirsionList,data).then(({ data }) => {
      setTimeout(() => {
        dispatch(fetchSuccess('New version added successfully.'));
      }, 2000);
      dispatch(fetchVirsionData());
    })  .catch(error => {
      
      if (error?.response?.status === 401) {
        dispatch(setAuthUser(null));
        history.push('/signin');
      } else {
        dispatch(fetchError(error?.response?.data?.message));
      }
      }
    );
  };
};

export const deleteVersion = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(virsionDelete+userId)
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(fetchSuccess('Version deleted successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError());
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
