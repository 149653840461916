import { GET_COMPANIES, GET_COMPANIES_PROFILE, ADD_COMPANIES, GET_COMPANIESFORTABLE, GET_COMPANIES_DETAILS, GET_COMPANIESFORMATCHWITHTABLE } from '../../@jumbo/constants/ActionTypes';
  
  const INIT_STATE = {
    users: [],
    companyProfile: [],
    currentUser: null,
    export:null,
    companiesStore: [],
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_COMPANIES: {
        return {
          ...state,
          companiesStore: action.payload,
        };
      }
      case GET_COMPANIES_DETAILS: {
        return {
          ...state,
          currentUser: action.payload,
        };
      }
      case GET_COMPANIES_PROFILE: {
        return {
          ...state,
          companyProfile: action.payload,
        };
      }
      case ADD_COMPANIES: {
        return {
          ...state,
          users: [action.payload, ...state.users],
        };
      }
      case GET_COMPANIESFORTABLE: {
        // console.log("action.payload",action.payload)
        return {
          ...state,
          users: action.payload,
        };
      }
      case GET_COMPANIESFORMATCHWITHTABLE: {
        return {
          ...state,
          companiesTableListForAddCompanies: action.payload,
        };
      }
      default:
        return state;
    }
  };
  