import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import ProfileApp from './ProfileApp';
import Setting from './Setting'
import SubAdminManagement from './SubAdminManagement'
import EndUserManagement from './EndUserManagement'
import Companies from './Companies'

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    profileApp: ProfileApp,
    COMPANIES:Companies,
    ENDUSERMANAGEMENT:EndUserManagement,
    SUBADMINMANAGEMENT:SubAdminManagement,
    SETTING:Setting,
  });
